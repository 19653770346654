import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getGlobal, setGlobal } from '../../hook/GlobalHook';
import './PaginaModulo.css';

function validaTelefono(t) {
    //const re = /^((00|\+)39[\. ]??)??3\d{2}[\. ]??\d{6,7}$/;
    const re = /^3[0-9]{2}[0-9]{6,7}$/;
    return re.test(t);
}
function validateEmail(email) {
    //const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\")){3,}@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\]){3,}|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = /^[\w._-]{3,}@[\w.-]{3,}\.[a-zA-Z]{2,4}$/;
    return re.test(email);
}


export function PaginaModulo(props) {

    //var motivi = [ { value: 'chocolate', label: 'Chocolate' },
    //];

    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [tel, setTel] = useState('');
    const [mail, setMail] = useState('');
    const [codice, setCodice] = useState('');
    const [motivo, setMotivo] = useState(null);
    const [motivi, setMotivi] = useState([]);
    const [ch_privacy, setPrivacy] = useState(false);


    useEffect(() => {
        let response = fetch(process.env.REACT_APP_PRENOTAZIONI_URL+'api/datasets.php?ds=motivi')
            .then(response => response.json())
            .then(commits => {
                setMotivi(commits);
            });
    }, [])

    function handlePrivacyChange(event) {
        setPrivacy(event.target.checked);
    }

    function validaCampi() {
        if ((nome == null) || (nome == undefined) || (nome.length < 3)) {
            props.alert("warning","Inserire un nome valido.");
            return false;
        }
        if ((cognome == null) || (cognome == undefined) || (cognome.length < 3)) {
            props.alert("warning","Inserire un cognome valido.");
            return false;
        }
        if ((tel == null) || (tel == undefined) || (!validaTelefono(tel))) {
            props.alert("warning","Inserire un contatto telefonico mobile valido.");
            return false;
        }
        if ((mail == null) || (mail == undefined) || (!validateEmail(mail))) {
            props.alert("warning","Inserire un contatto eMail valido.");
            return false;
        }
        if ((motivo == null) || (motivo == undefined)) {
            props.alert("warning","Seleziona il motivo della richiesta.");
            return false;
        }
        if ((motivo == null) || (motivo == undefined)) {
            props.alert("warning","Seleziona il motivo della richiesta.");
            return false;
        }
        if ((ch_privacy == null) || (ch_privacy == undefined) || (ch_privacy == false)) {
            props.alert("warning","Per procedere devi accettare il consenso al trattamento dei dati personali unicamente per la richiesta in oggetto, in base alle previsioni del Regolamento UE 2016/679 (GDPR). .");
            return false;
        }

        setGlobal({
            tel: tel,
            mail: mail,
            codCli: codice,
            nome: nome,
            cognome: cognome,
            motivo: motivo
        });

        sendData();

        return true;
    }

    async function sendData() {
        const formData = new FormData();

        formData.append("tel", getGlobal("tel"));
        formData.append("mail", getGlobal("mail"));
        formData.append("codCli", getGlobal("codCli"));
        formData.append("nome", getGlobal("nome"));
        formData.append("cognome", getGlobal("cognome"));
        formData.append("MOTIVO", getGlobal("motivo").label);
        formData.append("dataApp", getGlobal("dataApp"));
        formData.append("ORA", getGlobal("orario").value);
        formData.append("SPORTELLO", getGlobal("sportello").value);
    
       /* console.group("POST Values")
        console.log(getGlobal("motivo"));
        console.log(getGlobal("orario").value);
        console.log(getGlobal("sportello").value);
        console.groupEnd();*/

        const response = await fetch(process.env.REACT_APP_PRENOTAZIONI_URL+"insApp.php", {
            method: 'POST',
            body: formData
        }).then((response) => response.text());

        if(response.substring(0,5)=="[000]"){
            console.log(response);
            let p = response.substring(5,5+7);

            setGlobal({prenotazione: p});

            props.nextStep();

        }else{
            props.alert("error",'Errore durante la richiesta di prenotazione, riprova tra poco o contatta il servizio clienti.');
        }
        // ...
    }
    return <>
        <div className="pmod" id="modulo-box">
            <h1 className='mainColor'>Prenotazione accesso sportello</h1>
            <h2>Prenota qui la tua visita ed evita attese allo sportello!</h2>
            <div className="modulo-box mainBkColor">
                <div className="form1">
                    <h4>Componi il modulo</h4>
                    <form action="#">
                        <p className='mid'>
                            <label htmlFor="">Nome*</label>
                            <input type="text" value={nome} onChange={(e) => setNome(e.target.value.substring(0,40))} />
                        </p>
                        <p className='mid'>
                            <label htmlFor="">Cognome*</label>
                            <input type="text" value={cognome} onChange={(e) => setCognome(e.target.value.substring(0,40))} />
                        </p>
                        <p className='mid'>
                            <label htmlFor="">Telefono*</label>
                            <input type="text" value={tel} onChange={(e) => setTel(e.target.value.substring(0,20))} />
                        </p>
                        <p className='mid'>
                            <label htmlFor="">Email*</label>
                            <input type="text" value={mail} onChange={(e) => setMail(e.target.value.substring(0,40))} />
                        </p>
                        <p className='full'>
                            <label htmlFor="">Codice Cliente</label>
                            <input type="text" value={codice} onChange={(e) => setCodice(e.target.value.substring(0,40))} />
                        </p>
                        <div className='full'>
                            <label htmlFor="">Motivo appuntamento*</label>
                            <Select
                                id='motivo'
                                className='motivo'
                                defaultValue={motivo}
                                onChange={setMotivo}
                                options={motivi}
                                Disabled={false}
                                placeholder="Seleziona il motivo appuntamento"
                                noOptionsMessage={()=>"Caricamento..."}
                            />
                        </div>
                        <br />
                        <div style={{ textAlign: "left", width: "100%", display: "block", paddingTop: "10px" }}>
                            <p style={{ textAlign: "left" }}>
                                <input id='privacy' style={{ textAlign: "left", width: "auto", float: "left", }} type="checkbox"
                                    checked={ch_privacy} onChange={handlePrivacyChange} />
                                <label htmlFor="privacy" style={{ marginLeft: "20px" }}>Con l'invio del presente modulo acconsento al trattamento dei dati personali unicamente per la richiesta in
                                    oggetto, in base alle previsioni del Regolamento UE 2016/679 (GDPR). *</label>
                            </p>
                            <p>*Campi obbligatori</p>
                        </div>
                    </form>
                </div>
                <div style={{ textAlign: "center", width: "100%", display: "block", paddingTop: "10px" }}>
                    <button className='ue btn btn-yellow-outline btnCData' onClick={props.prevStep} >Cambia data</button>
                    <button className='ue btn btn-yellow' onClick={validaCampi}>VAI AVANTI</button>
                </div>
            </div>
        </div>
    </>
}