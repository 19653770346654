import { useEffect, useState } from "react";
import { Selector } from "./components/Selector/Selector.js";
//https://github.com/jakobinn/react-popup-alert
//custom css nel file alert.css
import Alert from 'react-popup-alert'
import { getGlobal } from "./hook/GlobalHook.js";



var step = 0;

function App() {
  const [alert, setAlert] = useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })

  useEffect(() => {
    console.log("KD software");
    if (process.env.NODE_ENV === "production") {
      if (window.location.protocol == 'http:') {

        console.log("you are accessing us via " +
          "an insecure protocol (HTTP). " +
          "Redirecting you to HTTPS.");

        window.location.href =
          window.location.href.replace(
            'http:', 'https:');
      }
    }
  }, []);

  function onCloseAlert() {
    setAlert({
      type: '',
      text: '',
      show: false
    })
  }

  function onShowAlert(type, text, header = 'MyUmbriaEnergy') {
    setAlert({
      type: type,
      header: getGlobal('ModalHeaderText'),
      text: text,
      show: true
    })
  }

  return (
    <div className="App">

      <div className="page-wrap">

        <Alert
          header={alert.header}
          btnText={'Chiudi'}
          text={alert.text}
          type={alert.type}
          show={alert.show}
          onClosePress={onCloseAlert}
          pressCloseOnOutsideClick={true}
          showBorderBottom={true}
          alertStyles={{}}
          headerStyles={{}}
          textStyles={{}}
          buttonStyles={{}}
        />

        <div className="header">
          <a href={getGlobal('mainUrl')} target={"_blank"} className="logo"></a>
          <a className="ue btn btn-yellow contatti" href="https://www.umbriaenergy.it/contatti" target={"_blank"}>CONTATTI</a>
        </div>

        <div className="mascotte"></div>


        <Selector step={step} alert={onShowAlert} />

      </div>
      <div className="footer">

        <div className="logofdiv">
          <a href={getGlobal('mainUrl')} target={"_blank"} className="logof"></a>

          <a href={getGlobal('waUrl')} target={"_blank"} className="logofwa"></a>
        </div>
        <br />
        <p style={{ clear: "both", marginTop: '40px', color: "white" }}>Per informazioni chiama il nostro numero verde 800.060.010 (0744/300281 da cellulare)</p>

        <span style={{ fontSize: "8px" }}>{process.env.REACT_APP_VERSION}</span>

        <div className="logofdivm">
          <a href={getGlobal('fbUrl')} target={"_blank"} className="fb"></a>
          <a href={getGlobal('ytUrl')} target={"_blank"} className="yt"></a>
          <a href={getGlobal('igUrl')} target={"_blank"} className="ig"></a>
          <a href={getGlobal('twUrl')} target={"_blank"} className="tw"></a>
        </div>

      </div>


    </div>

  );
}

export default App;
