import { useEffect, useState } from "react"

export function PaginaAnnulla(props) {

    const [prenotazione, setPrenotazione] = useState('');
    const [telefono, setTelefono] = useState('');

    async function annulla() {
        const formData = new FormData();

        formData.append("tel", telefono);
        formData.append("codPrenotazione", prenotazione);

        const response = await fetch(process.env.REACT_APP_PRENOTAZIONI_URL+"annullaApp.php", {
            method: 'POST',
            body: formData
        }).then((response) => response.text());

        let stato = response.substring(0, 5);

        console.log(response);
        
        switch (stato) {
            case '[000]':
                props.alert("success", 'La prenotazione è stata annullata.');
                setPrenotazione('');
                setTelefono('');
                break;
            case '[888]':
                props.alert("error", 'Errore durante la richiesta di prenotazione, le informazioni inserite non sono valide, riprova tra poco o contatta il servizio clienti.');
                setPrenotazione('');
                setTelefono('');
                break;
            case '[990]':
                props.alert("warning", 'La prenotazione indicata non è stata trovata, verifica che il codice prenotazione ed il numero di telefono con il quale è stata richiesta siano corretti.');
                setPrenotazione('');
                setTelefono('');
                break;

            default:
                props.alert("error", 'Errore durante la richiesta di prenotazione, riprova tra poco o contatta il servizio clienti.');
                break;
        }
    }

    return <>


        <div className="pcon" id="conferma-box">
            <h1 className="mainColor">Prenotazione accesso sportello</h1>
            <h4>Prenota qui la tua visita ed evita attese allo sportello!</h4>
            <div className="conferma-box mainBkColor">
                <img src="./img/icona-errore.png" height={"150px"} />
                <p style={{ backgroundColor: "#E24A4A", borderRadius: "3px", padding: "10px" }}><b>Qui puoi annullare il tuo appuntamento inserendo il numero prenotazione (es.: W00000) ed il numero di telefono con cui è stata effettuata.</b></p>
                <div className="form1">
                    <br></br>
                    <div>
                        <form action="#">
                            <p className='full'>
                                <label htmlFor="">Codice prenotazione</label>
                                <input type="text" value={prenotazione} onChange={(e) => setPrenotazione(e.target.value.toUpperCase().substring(0,10))} />
                            </p>
                            <p className='full'>
                                <label htmlFor="">Telefono</label>
                                <input type="text" value={telefono} onChange={(e) => setTelefono(e.target.value.toUpperCase().substring(0,15))} />
                            </p>
                        </form>

                        <div style={{ textAlign: "center", width: "100%", display: "block", paddingTop: "10px" }}>
                            <button className='ue btn btn-orange' onClick={annulla}>Annulla Prenotazione</button>

                            <a className='ue btn btn-yellow-outline' href="https://www.umbriaenergy.it/" target={"_blank"}>Contattaci</a>
                            <button className='ue btn btn-yellow-outline' onClick={props.homeStep}>NUOVA Prenotazione</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </>
}