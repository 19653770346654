import { useEffect } from "react"
import { getGlobal, logGlobal, setGlobal } from "../../hook/GlobalHook";
import { formatDateISO, formatDateIT } from "../PaginaCalendario/PaginaCalendario";
import { } from "./PaginaConferma.css";
//https://github.com/josephj/react-icalendar-link
import ICalendarLink from "react-icalendar-link";


function sntz(s){
    if((s!=undefined)&&(s!=null)){
        return s.replace(/\s/g, ',')
    }else{
        return '';
    }
}

export function PaginaConferma(props) {


    useEffect(() => {

    }, [])

    const event = {
        title: getGlobal("ModalHeaderText")+" - Prenotazione allo sportello",
        description: "Appuntamento presso "+getGlobal("sportello").label+", codice prenotazione "+getGlobal("prenotazione"),
        startTime: formatDateISO(getGlobal("data"))+"T"+getGlobal("orario").value,
        /*endTime: "2018-10-07T12:00:00+1:00",*/
        location: getGlobal("sportello").indirizzo,
        attendees: [
            getGlobal("nome")+" "+getGlobal("cognome")+"<"+getGlobal("mail")+">"
        ]
    }

    return <>


        <div className="pcon" id="conferma-box">

            <h1 className="mainColor">Grazie per aver prenotato!</h1>
            <p className="mainColor">La tua prenotazione verrà chiamata dal primo consulente libero a partire dall'orario scelto e comparirà sui monitor presenti allo sportello.<br/><b>Attenzione</b>: se non sarai presente al momento della chiamata, la prenotazione salta e non sarà ritenuta valida!</p>
            <div className="conferma-box mainBkColor">
                <img className="imgConferma" src="./img/icona-check.png" height={"150px"} />
                <h2>Il tuo codice prenotazione è : <span className="iY"><b>{getGlobal("prenotazione")}</b></span></h2>
                <div className="form1">
                    <br></br>
                    <div>
                        <h2>SEDE: <span className="iY">{getGlobal("sportello").label}</span></h2>
                        <h2>DATA: <span className="iY">{formatDateIT(getGlobal("data"))}</span></h2>
                        <h2>ORA: <span className="iY">{getGlobal("orario").value}</span></h2>
                        <div style={{ textAlign: "center", width: "100%", display: "block", paddingBottom: "-10px" }}>
                            <p>
                                <button className='ue btn btn-yellow btnStampa' onClick={() => { window.print(); }}>STAMPA PRENOTAZIONE</button>
                                <ICalendarLink event={event} className='ue btn btn-yellow btnICS'>
                                    Aggiungi al calendario
                                </ICalendarLink>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="conferma-footer">
                    <div className="stampa">
                        {getGlobal("sportello").indirizzo != '' && <iframe src={"https://www.google.com/maps?q=" + getGlobal("sportello").indirizzo + "&output=embed"} width="500" height="200" style={{ marginBottom: "5px", marginTop: "-60px", border: "none" }} />}
                        <p>Scansiona il QR Code per aprire la mappa sul tuo smartphone</p>
                        <img src={'https://barcode.tec-it.com/barcode.ashx?translate-esc=off&data=https://www.google.com/maps/place/' + sntz(getGlobal("sportello").indirizzo) + '&code=QRCode&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&qunit=Mm&quiet=0&eclevel=L'} />
                        <br />
                        {getGlobal("ModalHeaderText") == 'MyServizioElettrucoUmbria' && <img src="https://www.servizioelettricoumbria.it/wp-content/uploads/2022/11/SEU-logo.png" style={{ maxWidth: "200px", maxHeight: "50px" }} />}
                        {getGlobal("ModalHeaderText") == 'MyUmbriaEnergy' && <img src="https://www.umbriaenergy.it/wp-content/uploads/2020/03/cropped-logo-umbria-energy-Eco.png" style={{ maxWidth: "200px", maxHeight: "50px" }} />}

                        {getGlobal("ModalHeaderText") == 'MyServizioElettrucoUmbria' && <img src="./img/Logo-seuzzap.png" style={{ maxWidth: "200px", maxHeight: "50px" }} />}
                        {getGlobal("ModalHeaderText") == 'MyUmbriaEnergy' && <img src="./img/logo-uezzap.png" style={{ maxWidth: "200px", maxHeight: "50px" }} />}
                        <br />
                        <p style={{ clear: "both", marginTop: '40px', color: "black" }}>Per informazioni chiama il nostro numero verde 800.060.010 (0744/300281 da cellulare)</p>
                    </div>
                </div>
            </div>

        </div>


    </>
}