let _obj = {showInfo:true}


export const setGlobal = (obj) => {
   Object.assign(_obj, obj);
 //  console.table(_obj);
}
export const logGlobal = () => {
   console.table(_obj);
}
export const getGlobal = varName => {
   if(_obj[varName] !== undefined){
      return _obj[varName]
   }
   else {
      return null
   }
}