import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import { AppStep } from '../../App';
import { getGlobal, logGlobal, setGlobal } from '../../hook/GlobalHook';
import './PaginaCalendario.css';

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function formatDate(date = new Date()) {
    try {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('');
    } catch (error) {
        console.error("formatDate::" + date + "::" + error);
    }

}

export function formatDateIT(date = new Date()) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear()
        ,
    ].join('/');
}
export function formatDateISO(date = new Date()) {
    return [
        
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate())
        
        ,
    ].join('-');
}

function isWeekend(date = new Date()) {
    return date.getDay() === 6 || date.getDay() === 0;
}



export function PaginaCalendario(props) {
    const [sportelli, setSportelli] = useState([]);
    const [orari, setOrari] = useState([]);
    const [selectedSportello, setSelectedSportello] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedDate, onSelectedDate] = useState(null);
    const [disabledDates, setDisabledDates] = useState([]);
    const [orariLoading, setOrariLoading] = useState(false);
    const [sportelliLoading, setSportelliLoading] = useState(true);


    //evento selezione sportello
    const handleChangeSportello = event => {
        console.log(event.value);


        setSelectedSportello(event);

        setDisabledDates([]);
        onSelectedDate(null);
        setSelectedTime(selectedTime);

        checkData4AppArray(new Date(), event.value);


        setSelectedSportello(event);
    };

    //evento selezione data
    const handleChangeData = (event) => {
        if ((selectedSportello != null) && (selectedSportello != undefined)) {
            onSelectedDate(event);
            setSelectedTime(null);
            setOrariLoading(true);
            OrariSportello(event, selectedSportello.value);
        }
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_PRENOTAZIONI_URL+'api/datasets.php?ds=sportelli&t='+getGlobal('tipoPagina'))
            .then(response => response.json())
            .then(commits => {
                setSportelli(commits);
                setGlobal({ listaSportelli: commits });
                setSportelliLoading(false);
            });
        let s = getGlobal("sportello");
        //let d = getGlobal("data");
        //let o = getGlobal("orario");
        let dd = getGlobal("disabledDates");


        if ((s != undefined) && (s != null)) {
            setSelectedSportello(s);
        }
        if ((dd != undefined) && (dd != null)) {
            setDisabledDates(dd);
        }
        /*if ((d!=undefined)&&(d!=null)){
            onSelectedDate(d);
        }
        if ((o!=undefined)&&(o!=null)){
            setSelectedTime(o);
        }*/

    }, [])

    async function checkData4AppArray(date, sportello) {
        window.document.getElementById('calendario').style.opacity = '0.3';
        window.document.getElementById('calendario').style.pointerEvents = 'none';
        if (sportello != null) {
            var dateString = formatDate(date);
            const formData = new FormData();
            formData.append('sportello', sportello);
            formData.append('dataIni', dateString);
            let response = await fetch(process.env.REACT_APP_PRENOTAZIONI_URL+'checkDate4App.php', {
                method: 'POST',
                body: formData
            }).then(response => response.text())
                .then(commits => {
                    //console.log(dateString+" <"+commits+"> "+sportello);
                    return commits;
                });
            setDisabledDates(response.split(";"));
            window.document.getElementById('calendario').style.opacity = '1';
            window.document.getElementById('calendario').style.pointerEvents = 'auto';
            return response;
        } else {
            console.warn("selectedSportello is NULL");
            return []
        }
    }

    async function OrariSportello(date, sportello) {
        if ((sportello != null) && (sportello != undefined) && (date != undefined)) {
            var dateString = formatDate(date);
            const formData = new FormData();
            formData.append('sportello', sportello);
            formData.append('data', dateString);
            let response = await fetch(process.env.REACT_APP_PRENOTAZIONI_URL+'orariSportello2.php', {
                method: 'POST',
                body: formData
            }).then(response => response.text())
                .then(commits => {
                    console.log(dateString + " <" + commits + "> " + sportello);
                    return commits;
                });

            const orari_json = JSON.parse(response);
            let orari_tmp = [];

            orari_json.forEach(element => {
                orari_tmp.push({ label: element, value: element });
            });

            setOrari(orari_tmp);
            setOrariLoading(false);
            return response;
        } else {
            console.warn("selectedSportello is NULL");
            return []
        }
    }

    function tileDisabled({ date, view }) {
        // Disable tiles in month view only
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is on the list of disabled dates

            return disabledDates.find(dDate => {
                var dateParts = dDate.split("/");
                // month is 0-based, that's why we need dataParts[1] - 1
                var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

                var today = new Date();
                today = new Date(today.getFullYear(), today.getMonth(), today.getDate());

                return ((
                    date.getFullYear() === dateObject.getFullYear() &&
                    date.getMonth() === dateObject.getMonth() &&
                    date.getDate() === dateObject.getDate()
                ) || (isWeekend(date)) || date < today)
            });
        }
    }

    function valida() {
        if ((selectedDate == null) || (selectedSportello == null) || selectedTime == null) {
            props.alert("warning", "Per procedere seleziona lo spertello, la data e l'orario per il quale vuoi prenotarti");
            return false;
        }
        if ((selectedTime == null) || (selectedTime == undefined) || (selectedTime.value == 'Seleziona un orario')) {
            props.alert("warning", "Per procedere seleziona l'orario per il quale vuoi prenotarti");
            return false;
        }
        setGlobal({
            sportello: selectedSportello,
            data: selectedDate,
            dataApp: formatDate(selectedDate),
            orario: selectedTime,
            disabledDates: disabledDates
        });
        props.nextStep();

    }

    return <>


        <div className="pcal " id="calendario-box">
            <h1 className='mainColor'>Prenotazione accesso sportello</h1>
            <h2>Prenota qui la tua visita ed evita attese allo sportello!</h2>
            <div className="pcal-box mainBkColor">
                <p>Sportello*</p>
                <Select
                    id='sportello'
                    defaultValue={selectedSportello}
                    value={selectedSportello}
                    onChange={(e) => { handleChangeSportello(e) }}
                    options={sportelli}
                    isDisabled={false}
                    isLoading={sportelliLoading}
                    placeholder="Seleziona lo sportello"
                    noOptionsMessage={() => "In attesa della lista sportelli"}
                />

                <p>Seleziona il giorno preferito per l'appuntamento*</p>
                <div id="calendario">
                    <div className='claLeg' style={{ }}>
                        <b>LEGGENDA</b><br />
                        <br />
                        <span style={{ display: "block", marginTop: "2px", fontSize: '13px' }}><span style={{ display: "block", width: "10px", height: "10px", backgroundColor: "white", float: "left", marginTop: "3px", marginRight: "5px" }}></span> Giorno disponibile</span>
                        <br />
                        <span style={{ display: "block", marginTop: "2px", fontSize: '13px' }}><span style={{ display: "block", width: "10px", height: "10px", backgroundColor: "gray", float: "left", marginTop: "5px", marginRight: "3px" }}></span> Giorno NON disponibile</span>
                        <br />
                        <span style={{ display: "block", marginTop: "2px", fontSize: '13px' }}><span style={{ display: "block", width: "10px", height: "10px", backgroundColor: "#ffff76", float: "left", marginTop: "5px", marginRight: "3px" }}></span> Giorno corrente</span>
                        <br />
                        <span style={{ display: "block", marginTop: "2px", fontSize: '13px' }}><span style={{ display: "block", width: "10px", height: "10px", backgroundColor: "#006edc", float: "left", marginTop: "5px", marginRight: "3px" }}></span> Giorno selezionato</span>
                    </div>
                    <Calendar
                        onChange={(e) => { handleChangeData(e) }}
                        value={selectedDate}
                        tileDisabled={tileDisabled}
                        maxDate={new Date(new Date().setDate(new Date().getDate() + 60))}
                        minDate={new Date()} />

                </div>

                <p>Seleziona l'orario*</p>
                <Select
                    id='orario'
                    defaultValue={selectedTime}
                    value={selectedTime}
                    onChange={setSelectedTime}
                    options={orari}
                    isDisabled={false}
                    placeholder="Seleziona orario"
                    isLoading={orariLoading}
                    noOptionsMessage={() => "Seleziona una data per vedere gli orari disponibili"}
                />
                <div style={{ textAlign: "center", width: "100%", display: "block", paddingTop: "10px" }}>
                    <button className='ue btn btn-yellow' onClick={valida}>VAI AVANTI</button>
                </div>
            </div>
        </div>


    </>
}

