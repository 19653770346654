import { setGlobal } from '../../hook/GlobalHook';
import './PopupInfo1.css';

function closePopupInfo1(e) {
    if((e.target.id!='popupInfo1')&&(e.target.id!='btn_close')&&(e.target.id!='zammad-feedback-form')){ return; }
    let pi1 = window.document.getElementById('popupInfo1');
    pi1.classList.add('hidden');
    setTimeout(() => {
        pi1.style.display = 'none';
        pi1.style.visibility = 'hidden';
        window.document.body.style.overflow = 'auto';
    }, 510);
    setGlobal({showInfo:false});
}


export function PopupInfo1(params) {
    //const urlwa = 'https://wa.me/+393274045985?text=Ciao!";
    const urlwa = "https://api.whatsapp.com/send/?phone=%2B393274045985";
    
    window.scrollTo(1, 1);
    window.document.body.style.overflow = 'hidden'; 
    return <>
        <div className="popupInfo1" id="popupInfo1" onClick={closePopupInfo1}>
            <div className="popupInfo1-box">
                <h3>Hai mai provato a contattarci con WhatsApp o con un Ticket Assistenza?</h3>
                <p>Se scegli un canale digitale di contatto, puoi evitare di recarti ai nostri Sportelli e risparmiare tempo.</p>
                <p>Un nostro consulente risponderà al più presto alla tua richiesta in orario lavorativo</p>
                <div className="buttonBoxContainer">
                    <div className="buttonBoxL">
                        <img src='./img/logo-uezzap.png'/>
                        <br/>
                        <button className="ue btn btn-yellow-outline" onClick={()=>window.open(urlwa)}>SCRIVI SU WHATSAPP</button>
                    </div>
                    <div className="buttonBoxR">
                        <img src='./img/icona-ticket.png'/>
                        <br/>
                        <button className="ue btn btn-yellow-outline"  id="zammad-feedback-form">APRI UN TICKET</button>
                    </div>
                </div>
                <button className="ue btn btn-yellow" id="btn_close" onClick={closePopupInfo1}>PROSEGUI CON LA PRENOTAZIONE DELLA VISITA</button>
            </div>
        </div>
        
    </>
}