import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/common.css';
import './styles/alert.css';
import { setGlobal } from './hook/GlobalHook';

const UETheme = React.lazy(() => import('./styles/ueTheme'));
const SEUTheme = React.lazy(() => import('./styles/seuTheme'));

const ThemeSelector = ({ children }) => {
  //const CHOSEN_THEME = new URLSearchParams(document.location.search).get('id') || "ue";

  var CHOSEN_THEME = "ue";

  let get_id = new URLSearchParams(document.location.search).get('id') || '';
  let get_t = new URLSearchParams(document.location.search).get('t') || '';

  if ((get_id == '') && (get_t == '')) {
    CHOSEN_THEME = "ue";
  } else {
    if (get_id != '') {
      CHOSEN_THEME = get_id;
    }
    if (get_t != '') {
      switch (get_t) {
        case 'T':
            CHOSEN_THEME = 'seu';
          break;
        case 'T':
          CHOSEN_THEME = 'ue';
          break;          
      
        default:
          break;
      }
    }
  }

  var selected_theme = 'ue';
  if ((CHOSEN_THEME != 'ue') && (CHOSEN_THEME != 'seu')) {
    selected_theme = 'ue';
    // setGlobal({showInfo:true});
  } else {
    selected_theme = CHOSEN_THEME;
  }

  if (selected_theme == 'seu') {
    setGlobal({ showInfo: false });
    setGlobal({ mainUrl: 'https://www.servizioelettricoumbria.it' });
    setGlobal({ ModalHeaderText: "MyServizioElettrucoUmbria" });
    setGlobal({ tipoPagina: "T" });
    setGlobal({fbUrl:"https://www.facebook.com/umbriaenergy"});
    setGlobal({igUrl:"https://www.instagram.com/umbriaenergy/"});
    setGlobal({ytUrl:""});
    setGlobal({twUrl:""});
  } else
    if (selected_theme == 'ue') {
      setGlobal({ mainUrl: 'https://www.umbriaenergy.it' });
      setGlobal({ waUrl: 'https://www.umbriaenergy.it' });
      setGlobal({ ModalHeaderText: "MyUmbriaEnergy" });
      setGlobal({ tipoPagina: "L" });
      setGlobal({fbUrl:"https://www.facebook.com/umbriaenergy"});
      setGlobal({igUrl:"https://www.instagram.com/umbriaenergy/"});
      setGlobal({ytUrl:""});
      setGlobal({twUrl:""});
    }
  setGlobal({ azienda: selected_theme });
  return (
    <>
      <React.Suspense fallback={<></>}>
        {(selected_theme === "ue") && <UETheme />}
        {(selected_theme === "seu") && <SEUTheme />}
      </React.Suspense>
      {children}
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeSelector>
      <App />
    </ThemeSelector>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
