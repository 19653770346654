import { useEffect, useState } from "react";
import { getGlobal } from "../../hook/GlobalHook";
import { BoxLateraleDx } from "../BoxLateraleDx/BoxLateraleDx";
import { PaginaAnnulla } from "../PaginaAnnulla/PaginaAnnulla";
import { PaginaCalendario } from "../PaginaCalendario/PaginaCalendario";
import { PaginaConferma } from "../PaginaConferma/PaginaConferma";
import { PaginaModulo } from "../PaginaModulo/PaginaModulo";
import { PopupInfo1 } from "../PopUp/PopupInfo1";

export function Selector(props) {
    const [page, setPage] = useState("calendario");

    let lsPage = window.localStorage.getItem('action');
    if (lsPage=='annulla'){
        window.localStorage.setItem('action','');
        setPage('annulla');
        console.log('action: '+lsPage);
    }

    function gotoAnnullla(){
        setPage('annulla'); 
        window.scrollTo(1, 1);
    }

    useEffect(()=>{window.scrollTo(1, 1);},[]);
    switch (page) {
        case 'calendario':
            return <>
                {getGlobal("showInfo") == true && <PopupInfo1 />}
                <PaginaCalendario nextStep={() => { setPage('modulo'); window.scrollTo(1, 1);}} alert={props.alert} />
                <BoxLateraleDx annullaStep={gotoAnnullla}/>
            </>

            break;
        case 'modulo':
            return <><PaginaModulo prevStep={() => { setPage('calendario'); window.scrollTo(1, 1);}} alert={props.alert} nextStep={() => { setPage('conferma'); window.scrollTo(1, 1);}} /><BoxLateraleDx annullaStep={gotoAnnullla}/></>
            break;
        case 'conferma':
            return <><PaginaConferma alert={props.alert} /><BoxLateraleDx annullaStep={gotoAnnullla}/></>
            break;
        case 'annulla':
            return <><PaginaAnnulla alert={props.alert} homeStep={() => { setPage('calendario'); window.scrollTo(1, 1);}}/><BoxLateraleDx annullaStep={gotoAnnullla}/></>
            break;

        default:
            break;
    }
}