import './BoxLateraleDx.css';

export function BoxLateraleDx(props) {
    return <>
        <div className="R-colbox">
        <div className='box mainBorderColor'>
                
                <h2><img src='./img/icona-calendario.png'></img> Dati prenotazione</h2>
                <p>Seleziona lo Sportello di tuo interesse, inserisci la data e l'ora di quando vorresti prenotare le tua visita e controlla la disponibilità.</p>
                <p>Il tuo codice verrà chiamato dal primo operatore libero a partire dall'orario prenotato.</p>
                <p className='mainBkColor' style={{color:"white",padding:"5px"}}>Attenzione: se non sarai presente al
                    momento della chiamata, la
                    prenotazione salta e non sarà
                    ritenuta valida!</p>
            </div>
            <div className='box box2 mainBorderColor'>
                <h2><img src='./img/icona-errore.png'></img> Devi annullare una prenotazione?</h2>
                <span>
                <button className='ue btn btn-orange' onClick={props.annullaStep}>Annulla Prenotazione</button>
                </span>                             
            </div>
        </div>
    </>
}
